.jackpot-cell {
    display: flex;
    margin: 0 1px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -webkit-border-radius: 3px;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    min-width: 20px;
    min-height: 29px;
    font-size: 16px;
    font-weight: bold;

    @media only screen and (max-width: 990px) {

        font-size: 14px;
        padding: 0;
        min-width: 17px;
    }
}