.header {
  background: #1c1f2c;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
  @media only screen and (max-width: 530px) {
    overflow: hidden;
  }

  &__settings-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.7rem;
    height: 100%;
    margin-left: 5px;
  }

  &__user-block {
    padding: 6px 10px 6px 6px;
    display: flex;
    align-items: center;
    background-color: #252936;
    border-radius: 7px;
    height: 2.43rem;
  }

  &__user-icon {
    margin-right: 10px;
    position: relative;
    height: 2.43rem;
    width: 2.43rem;
    border-radius: 100%;
  }

  &__user-svg {
    height: 100%;
    width: 100%;
    line-height: 0;
  }

  &__pencil {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  &__user-info {
    display: flex;
    margin-left: 0.72rem;
    padding: 6px 10px 6px 10px;
    border: 1px solid #363a49;
    border-radius: 7px;
    height: 2.43rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.93rem;
    color: #8d97a1;
    line-height: 42px;
    text-transform: uppercase;
    @media only screen and (max-width: 530px) {
      border: none;
      font-size: 12px;
      padding: 0;
      margin-left: 0;
      border-radius: 0;
      line-height: 0;
      height: 0;
    }
  }

  &__nick-icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    text-transform: uppercase;
    color: #bec4cb;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 165px;
    margin-bottom: 5px;
  }

  &__user-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__user-nick {
    color: #bec4cb;
    text-transform: uppercase;
    line-height: 1.1rem;
    font-size: 1.15rem;
  }

  &__icon {
    width: 1.1rem;
    max-height: 1.1rem;
    display: block;
    line-height: 0;
    &-svg {
      max-height: 1.1rem;
      * {
        fill: #FFF;
      }
    }
  }
  &__balance {
    display: flex;
    align-items: center;
    color: #ffcc00;
    font-size: 0.93rem;
    line-height: 42px;
    text-transform: uppercase;
    @media only screen and (max-width: 530px) {
      line-height: 0;
    }
  }

  &__player {
    display: flex;
    font-size: 0.7rem;
    flex-grow: 2;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  &__round-info {
    margin-left: 0.8rem;
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__user-id {
    font-size: 1rem;
    line-height: 1rem;
    margin-left: .5rem;
  }
}

@keyframes opacityBalance {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
    transform: scale(1)
  }
  50% {
    transform: scale(1.6)
  }
  85% {
    opacity: 1;
    transform: scale(1)
  }
  100% {
    opacity: 0;
  }
}