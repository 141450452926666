.jackpot-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 8px;
  padding: 6px 8px;
  list-style: none;
  border-radius: 6px;
  font-size: 16px;
  color: white;
  font-family: 'square', Arial;
  @media only screen and (max-width: 990px) {
    margin: 0 3px;
    padding: 3px;
  }
  &__icon {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 10px;
    @media only screen and (max-width: 990px) {
      width: 24px;
      height: 100%;
      margin-right: 3px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__svg {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__name {
    width: 35%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px #FFFFFF;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: 0.1em;
    &:first-child {
      padding-right: 5%;
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      padding-left: 5%;
      margin-left: auto;
      border-radius: 0 10px 10px 0;
    }
  }
  &__amount {
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }:nth-child(4n) {
    margin-left: 9px;
  }
  &__currency {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding-left: 2px;
    line-height: 28px;
    margin-left: 6px;
    font-weight: bold;
    box-sizing: border-box;
    @media only screen and (max-width: 990px) {
      margin-left: 3px;
    }
  }
  &__winner {
    font-size: .6rem;
    line-height: .8rem;
    white-space: nowrap;
    position: absolute;
    z-index: 2;
    background: #13171D;
    top: 100%;
  }
  .odometer {
    color: #FF8D00;
    font-family: Roboto;
    font-style: normal;
    font-style: italic;
    letter-spacing: 0.2em;
    white-space: nowrap;
  }
}

@media screen and (min-width: 700px) {
  .jackpot-item {
    &__name {
      font-size: .9rem;
    }
    &__currency {
      font-size: 1.29rem;
    }
    .odometer {
      font-size: .8rem;
    }
  }
}
