.statistic {
  width: 100%;
  display: flex;
  padding: 0.4rem;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  font-weight: bold;
  font-size: .9rem;
  height: 100%;
  overflow: hidden;
  align-items: center;
  &__coefs-container {
    width: calc(100% - .6rem);
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    overflow-y: hidden;
  }
  &__coef {
    display: block;
    min-width: 66px;
    padding: 0 5px;
    font-size: 14px;
    line-height: 25px;
    border-radius: 8px;
    text-align: center;
    font-weight: lighter;
    cursor: pointer;
  }
  &__arrow {
    cursor: pointer;
    background: #464c63;
    border-radius: 8px;
    height: 25px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 1.2rem;
      min-width: 1.2rem;
      height: 1.2rem;
    }
  }
}

@media screen and (min-width: 700px) {
  .statistic {
    // height: 100%;
    // padding: 0.5rem .6rem;

    // &__coefs-container {
    //   &_isOpen {
    //     height: 9rem;
    //   }
    // }
    // &_isOpen {
    //   height: 9rem;
    // }
    // &__coef {
    //   width: 10.5%;
    //   height: 1.25rem;
    //   margin-bottom: .6rem;
    // }
  }
}

@keyframes increace {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 100vh;
  }
}

@keyframes reduce {
  0% {
    display: flex;
    max-height: 100vh;
  }
  99% {
    display: flex;
  }
  100% {
    display: none;
    max-height: 0;
  }
}

@keyframes increaseCoef {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 10.5%;
  }
}
