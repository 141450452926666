.history {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background: #111318;
  overflow: hidden;
  box-sizing: border-box;
  font-size: .9rem;

  &_isClosingHistory {
    animation: reduceHistory 0.3s linear forwards;
  }
  &__buttons-container {
    width: 100%;
    min-height: 3.6rem;
    display: flex;
    justify-content: space-between;
    background-color: #111318;
  }
  &__button-container {
    width: 33%;
    height: 100%;
  }
  &__content-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #2d313f;
    padding: 8px;
    box-sizing: border-box;
    &::-webkit-scrollbar {
      margin-top: 3px;
      width: 2px;
      height: 0;
      opacity: 0;
    }
    &::-webkit-scrollbar-track {
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #111318;
      border-radius: 50px;
    }
  }
}

@media screen and (min-width: 700px) {
  .history {
    position: inherit;
    animation: none;
    border-radius: 10px;
  }
}

@keyframes increaceHistory {
  0% {
    max-height: 0;
    padding: 0 1rem;
  }
  100% {
    padding: 1rem;
    max-height: calc(100% - 4.2rem);
  }
}

@keyframes reduceHistory {
  0% {
    padding: 1rem;
    max-height: calc(100% - 4.2rem);
  }
  100% {
    max-height: 0;
    padding: 0 1rem;
  }
}