.my-bets {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    color: #8d97a1;
    padding: 5px 8px 5px 12px;
    background: #252936;
    border-radius: 10px;
    min-height: 50px;
    margin-bottom: 2px;
  }
  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    row-gap: 2px;
  }
  &__item {
    display: flex;
    box-sizing: border-box;
    background:  #1c1f2c;
    border-radius: 10px;
    overflow: hidden;
    max-height: 50px;
    height: 50px;
    padding: 5px 8px 5px 12px;
    align-items: center;
    justify-content: space-between;
  }
  &__column {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__column-icon {
    width: 50%;
    max-width: 25%;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    justify-content: center;
  }
  &__icon {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 700px) {
  .my-bets {
    &__items {
      max-height: initial;
    }
    &__header {
      &_lang {
        &_it {
          font-size: .75rem;
        }
      }
    }
  }
}

@keyframes increaceResult {
  0% {
    max-height: 0;
    padding: 0 0.5rem;
  }
  100% {
    padding: 1rem 0.5rem;
    max-height: 4rem;
  }
}