.animation-container {
    display: flex;
    flex-direction: column;
    height:  4rem;
    width: 100%;
    background: #1c1f2c;
    &_isVertical{
        flex-direction: row-reverse;
        height: calc(100% + 1.8rem);
        width: 4rem;
        margin-left: 0;
        z-index: 100;
    }
    &__horizont-shadow {
        background: linear-gradient(to right, #8d97a1, #1c1f2c);
        width: 100%;
        display: flex;
        height: 3px;
        &_isVertical {
            background: linear-gradient(to top, #8d97a1, #1c1f2c);
            width: 3px;
            height: calc(100% - 1.8rem + 3px);
        }
    }
    &__to-left-animation {
        display: flex;
        width: 100%;
        height: calc(100% - 3px);
        background: 0 35% repeat-x url('./img/dot.png');
        animation: normal, reverse 20s linear  infinite horisontalAnimation;
        &_isVertical {
            height: 100%;
            width: calc(100% - 3px);
            background: 65% 100% repeat-y url('./img/dotV.png');
            animation: normal, reverse 10s linear  infinite verticalAnimation;
        }
    }
}

@keyframes horisontalAnimation {
        from { background-position: 0 35%; }
        to { background-position: 100% 35%; }
}

@keyframes verticalAnimation {
    from { background-position: 65% 100%; }
    to { background-position: 65% 0; }
}