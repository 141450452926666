.jackpot {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  column-gap: 5px;
  @media only screen and (max-width: 530px) {
    column-gap: 2px;
  }
  @media only screen and (max-width: 422px) {
    scale: 0.9;
  }
  &__item {
    width: 100%;
  }
}