.basket-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    background: #1c1f2c url('../img/dirigable.webp') no-repeat;
    padding: 10px 12px;
    border-radius: 10px;
    column-gap: 15px;
    width: 100%;
    @media only screen and (max-width: 640px) {
        flex-direction: column;
        font-size: 12px;
    }

    &__bets-container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        text-transform: uppercase;
        box-sizing: border-box;
        flex-direction: column;
        &_isAlone {
            flex-direction: row;
            @media only screen and (max-width: 640px) {
                flex-direction: column;
            }
        }
    }

    &__left {
        width: 100%;
        padding-right: 15px;
        height: 100%;
        margin-bottom: 9px;
        box-sizing: border-box;
        &_isAlone {
            width: 55%;
            border-right: 1px solid rgba(89, 95, 118, 0.4);
            @media only screen and (max-width: 640px) {
                width: 100%;
                padding-right: 0;
                border-right: none;
                margin-bottom: 0;
            }
        }
    }

    &__left-content {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        column-gap: 9px;
    }

    &__content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @media only screen and (max-width: 640px) {
            width: 50%;
        }
    }

    &__label {
        margin-bottom: 15px;
        color: white;
        @media only screen and (max-width: 640px) {
            font-size: 12px;
        }
    }

    &__row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 22.6%;
        box-sizing: border-box;
        margin-bottom: 25px;
        @media only screen and (max-width: 640px) {
            margin-bottom: 12px;
        }
        &_size {
            &_big {
                height: 50%;
            }
        }
    }

    &__change-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #595f76;
        box-sizing: border-box;
        border-radius: 10px;
        background: rgba(192, 203, 218, 0.2);
        border-radius: 10px;
        color: white;
        height: 46px;
        font-size: 20px;
        box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.2);
    }

    &__change-block {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        box-sizing: border-box;

        &_isBlock {
            opacity: .3;

            * {
                cursor: not-allowed;
            }
        }
    }

    &__change-currency {
        background: #595f76;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 10px 10px 0;
        width: 3.4rem;
        line-height: 39px;
        font-size: 19px;
        color: white;
        box-sizing: border-box;
        padding: 15px;
    }

    &__text {
        white-space: nowrap;
    }

    &__bet-multipliers-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 9px;
        margin-bottom: 9px;
    }

    &__bet-block {
        width: 28%;
        height: 100%;
        padding: 13px 0px 12px 0px;
        font-size: 17.5px;
        background: #c0cbda;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        transition: all 0.2s ease-in;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
        cursor: pointer;
        text-align: center;

        &_size {
            &_big {
                width: calc(50% - .25rem);
            }
        }
    }

    &__text-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 5px;
    }

    &__button-title {
        font-size: 21px;
        @media only screen and (max-width: 422px) {
            font-size: 18px;
          }
    }

    &__right {
        width: 100%;
        height: 100%;
        border-left: none;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        &_isAlone {
            width: 45%;
            padding: 0 0 0 15px;
            margin-top: 9px;
            @media only screen and (max-width: 640px) {
                width: 100%;
                margin-top: 0;
                padding: 0;
            }
        }

    }
}