.pause {
  // position: absolute;
  display: flex;
  // left: 0;
  // bottom: 0;
  width: 100%;
  height: 100%;
  background: url(./img/bg.png) center no-repeat;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 1.2rem;
  row-gap: 10px;
  border-radius: 10px;
  text-transform: uppercase;
  &__zeppelin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 112px;
  }
  &__animation {
    width: 250px;
    height: 10px;
    background: #464c63;
    border-radius: 10px;

  }
  &__progress {
    animation-name: wickAnimation;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    background: #ffcc00;
    border-radius: 10px;
    height: 10px;
  }
  &__icon {
    height: 112px;
    width: 150px;
    
    svg {
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (min-width: 700px) {
  .pause {
    font-size: 1.8rem;
  }
}

@keyframes wickAnimation{
  0% {
    width: 0
  }
  100%{
    width: 100%;
  }
}