.subheader {
  background: #1c1f2c;
  width: 100%;
  height: 100%;
  min-height: 4.65rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;

  &__settings {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__guard {
    width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    min-width: 2.5rem;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__guard-icon {
    width: 2.5rem;
    max-height: 2.5rem;
    display: block;
    line-height: 0;
    &-svg {
      max-height: 2rem;
      * {
        fill: #FF8D00;
      }
    }
  }

  &__item {
    width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 100%;
    margin-right: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media only screen and (max-width: 422px) {
      margin-right: 5px;
    }
  }
  &__icon {
    width: 2.15rem;
    max-height: 2.15rem;
    display: block;
    line-height: 0;
    position: relative;
    opacity: 0.7;
    &-svg {
      max-height: 2.15rem;
    }
    &:hover {
      opacity: 1;
    }
  }
}