.button {
  color: #fff;
  font-weight: 500;
  border: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  outline: none;
  white-space: initial;
  display: flex;
  justify-content: center;
  align-items: center;

  &_isVertical {
    text-orientation: upright;
    writing-mode: vertical-rl;
  }

  // &_isBlock {
  //   outline: rgba(0, 0, 0, 0.4) solid 20rem;
  // }

  &_isDisabled {
    opacity: .5;
    cursor: not-allowed !important;
  }

  &_isUppercase {
    text-transform: uppercase;
  }
  &_isBold {
    font-weight: bold;
  }
  &_fontSize {
    &_small {
      font-size: 0.8rem;
      line-height: .8rem;
    }
    &_normal {
      font-size: 1rem;
    }
    &_large {
      font-size: 1.2rem;
    }
  }
  &_color {
    &_orange {
      color: white;
      background: #2d313f;
      border-radius: 10px 10px 0 0;
    }
    &_dark {
      background: #252936;
      color: #8d97a1;
      border-radius: 10px 10px 0 0;
    }
    &_red {
      background: #ad2f2f;
      border-radius: 10px;
      padding: 31px 5px 31px 5px;
      font-size: 21px;
      color: #fff;
      @media only screen and (max-width: 422px) {
        font-size: 16px;
      }
    }
    &_yellow {
      background: #ffcc00;
      border-radius: 10px;
      padding: 31px 5px 31px 5px;
      font-size: 21px;
      color: black;
      @media only screen and (max-width: 422px) {
        font-size: 16px;
      }
    }
    &_green {
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;
      box-shadow: 0 2px 2px rgba(0,0,0,0.4);
      border-radius: 10px;
      padding: 31px 5px 31px 5px;
      font-size: 21px;
      box-sizing: border-box;
      @media only screen and (max-width: 422px) {
        font-size: 16px;
      }
    }
  }
  &_size {
    &_container {
      width: 100%;
      height: 100%;
    }
  }
  &__layout {
    &_size {
      &_container {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .button {
    cursor: pointer;
    &_fontSize {
      &_small {
        font-size: .9rem;
      }
      &_normal {
        font-size: 1rem;
        line-height: 1rem;
      }
      &_large {
        font-size: 1.25rem;
      }
    }
  }
}