.statistic-component {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(17, 19, 24, 0.9);
    z-index: 1000000;

    &__container {
        display: flex;
        flex-direction: column;
        background: #1f212e;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        width: 45.4%;
        height: 53%;
        border-radius: 10px;
        @media only screen and (max-width: 530px) {
            width: 90%;
          }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #2d313f;
        border-radius: 10px 10px 0 0;
        width: 100%;
        height: 3.9rem;
        padding: 15px;
        box-sizing: border-box;
    }

    &__svg {
        height: 21px;
        width: 16.1px;
        color: rgb(141, 151, 161);

        &:hover {
            color: rgb(255, 204, 0);
        }
    }

    &__content {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            margin-top: 3px;
            width: 2px;
            height: 0;
            opacity: 0;
        }

        &::-webkit-scrollbar-track {
            border-radius: 50px;
            margin-top: 1.7rem;
            margin-bottom: 1.7rem;
            background: #2A3040;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #111318;
            border-radius: 50px;
        }

    }

    &__bets-container {
        height: 100%;
        min-height: 230px;
        font-size: 15px;
        line-height: 19px;
    }

}

.bets-coef {
    display: inline-block;
    padding: 2px 0;
    border-radius: 5px;
    background: #2d313f;
    font-size: 14px;
    margin: 5px 2px;
    width: 65px;
    text-align: center;
}