.top-wins {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    color: #8d97a1;
    padding: 5px 8px 5px 12px;
    background: #252936;
    border-radius: 10px;
    min-height: 50px;
    margin-bottom: 2px;
  }
  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    row-gap: 2px;
  }

  &__arrow {
    width: 1.4rem;
    transition: all 0.5s linear;
    &_isRotate {
      transform: rotate(180deg);
    }
  }
  &__item {
    width: 100%;
    background:  #1c1f2c;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    max-height: 50px;
    height: 50px;
    padding: 5px 8px 5px 12px;
    align-items: center;
  }
  &__column {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__column-icon {
    width: 50%;
    max-width: 25%;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    justify-content: center;
  }
  &__icon {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__row {
    white-space: nowrap;
  }
  &__guard {
    width: 1rem;
    position: absolute;
    bottom: 0;
  }
}

@media screen and (min-width: 700px) {
  .top-wins {
    max-height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      margin-top: 3px;
      width: 2px;
      height: 0;
      opacity: 0;
    }
    &::-webkit-scrollbar-track {
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #111318;
      border-radius: 50px;
    }
    &__arrow {
      display: none;
    }
    &__item {
      width: 100%;
    }
  }
}