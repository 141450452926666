.chat {
  background: #2A3040;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__top-block {
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: 500;
    font-size: 1.1rem;
    color: #8d97a1;
    background: #2d313f;
    padding: 15px 14px 17px 14px;
    box-sizing: border-box;
    border-radius: 10px 10px 0 0;
  }

  &__ellipse {
    width: .6rem;
    margin-right: .5rem;
  }

  &__switcher {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__arrow {
    margin-left: 0.3rem;
    width: 1.2rem;
    transition: all .5s ease-out;

    &_isOpen {
      transform: rotate(180deg);
    }
  }

  &__bottom-block {
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: 500;
    font-size: 1.1rem;
    color: #8d97a1;
    background: #1c1f2c;
    padding: 0 1rem .6rem 1rem;
    box-sizing: border-box;
    border-radius: 0 0 10px 10px;
    column-gap: 5px;
  }

  &__input-container {
    height: 4rem;
    background: #1c1f2c;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  &__send {
    width: 1.1rem;
    height: 1.1rem;
  }

  &__counter {
    margin-left: auto;
  }

  &__drop {
    display: flex;
    align-items: center;
  }

  &__drop-icon-container {
    width: 15px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__drop-icon {
    width: 100%;
    height: 100%;

    svg {
      height: 100%;
    }
  }

  &__text-drop {
    font-size: 13px;
  }

  &__messages {
    width: 100%;
    transition: all 1s linear;
    overflow: hidden;
    margin-top: 1rem;
    height: 100%;
    max-height: 100%;
  }

  &__gif {
    display: block;
    padding: 0 8px;
    line-height: 17px;
    font-size: 10px;
    color: black;
    text-transform: uppercase;
    text-align: center;
    background: #555c75;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    cursor: pointer;
  }

  &__icon-container {
    width: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .EmojiPickerReact {
    --epr-emoji-size: 24px;
    --epr-picker-border-color: rgb(70, 76, 98);
    --epr-picker-border-radius: 4px 4px 0 4px;
    --epr-hover-bg-color: rgb(70, 76, 98);
    --epr-bg-color: rgb(70, 76, 98);
    --epr-category-label-bg-color: rgb(70, 76, 98);
    --epr-text-color: white;
    --epr-search-input-placeholder-color: #8d97a1;
    --epr-focus-bg-color: rgba(42, 46, 59, 0.2);
    --epr-category-icon-active-color: white;
  }

  & aside.EmojiPickerReact.epr-main {
    font-size: 16px;
    height: 361px;
    width: 338px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.8);
    position: absolute;
    bottom: 157px;
    right: 2rem;
    border: none;
    @media screen and (max-width: 1279px) {
      left: 2rem;
    }
  }

  & .epr-header {
    display: flex;
    flex-direction: column-reverse;
  }

  & .epr-category-nav {
    border-bottom: 1px solid white;
  }

  & .epr-active {
    border-bottom: 3px solid white;
  }

  & .epr-skin-tones {
    display: none;
  }

  & .epr-preview {
    display: none;
  }

  & .EmojiPickerReact .epr-search-container input.epr-search {
    background: #252734;
    border: none;
    font-size: 16px;
  }
}

@media screen and (min-width: 700px) {
  .chat {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__messages {
      height: initial;
      max-height: initial;
      flex-grow: 1;
      margin-top: 1rem;
    }

    &__switcher {
      display: none;
    }
  }
}