.game {
  height: 100%;
  width: 100%;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;

  &__left-header-layout {
    width: 100%;
    height: 4.65rem;
    padding: 0 20px;
  }

  &__statistic-layout {
    width: 100%;
    height: 5%;
    border-radius: 0 0 10px 10px;
    background: #252936;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    column-gap: 5px;
    box-sizing: border-box;

    @media screen and (max-width: 1279px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &__game-components {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
    box-sizing: border-box;
    @media screen and (max-width: 1279px) {
      width: 100%;
    }
  }

  &__jackpot-layout {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 4%;
  }

  &__history-layout {
    width: 23.9%;
    margin-left: 5px;
    height: calc(100vh - 122px);
    max-height: calc(100vh - 122px);
    @media screen and (max-width: 1279px) {
      width: 100%;
      height: 100%;
      margin-left: 0;
    }
  }

  &__game-layout {
    background: #1c1f2c;
    border-radius: 10px 10px 0 0;
    box-sizing: border-box;
    width: 100%;
    height: 70.7%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__vertical-animation {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
  }

  &__game-window-container {
    width: 100%;
    height: 100%;
  }

  &__basket-layout {
    width: 100%;
    margin-top: 5px;
  }

  &__chat-layout {
    width: 20%;
    height: calc(100vh - 122px);
    max-height: calc(100vh - 122px);
    @media screen and (max-width: 1279px) {
      width: 100%;
    }
  }

  &__chat-container {
    height: 100%;
  }

  &__footer {
    margin: 0 5px;
    border-radius: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    color: #8d97a1;
    background: #1c1f2c;
    padding: 10px 20px 9px 20px;
    font-family: 'mtavruli', Arial;
    line-height: 19px;
    width: 99%;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    @media screen and (max-width: 1279px) {
      border-radius: 10px 10px 0 0;
      justify-content: center;
      background: #282c3b;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-family: 'mtavruli', Arial;
    text-transform: uppercase;
    height: 100%;
    line-height: 14px;
    width: 20%;
    svg {
      width: 32px;
      height: 22px;
    }
  }
}