.winning-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 5px 10px;
    top: 50%;
    left: calc(50% - 125px);
    box-sizing: border-box;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000000;
    width: 270px;
    height: 60px;
    column-gap: 10px;
    overflow: hidden;
    @media only screen and (max-width: 530px) {
        scale: 0.7;
      }
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 30px);
        height: 100%;
    }
    &__item {
        display: flex;
        height: 100%;
        width: 50%;
        flex-direction: column;
        font-size: 12px;
        padding: 10px;
        align-items: center;
        box-sizing: border-box;
        overflow: hidden;
        &_win {
            background: #2ecd45;
            border-radius: 10px;
            box-shadow: 0px 0px 4px #2ecd45;
        }
    }
    &__text {
        font-weight: bold;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }

    &__icon {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 35%;
            height: 35%;
        }
    }

    &__button {
        background: #2d313f;
        border-radius: 5px;
        height: 30px;
        width: 30px;
    }
}