.firework {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  &__animation-container {
    background: url('../img/line.webp');
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    &_isAnimation {
      animation: animationContainerStart 15s ease-in forwards,
      animationContainer 5s linear 15s infinite;

      &.firework__animation-container_isHorizon {
        animation: animationContainerHorizonStart 15s ease-in forwards,
         animationContainerHorizon 5s linear 15s infinite;
      }
    }
    &_isHorizon {
      background: none;
    }
    &_isPauseAnimation {
      animation-play-state: paused !important;
    }
  }
  &__rocket {
    height: 112px;
    width: 150px;
    position: absolute;
    top: -85px;
    right: -85px;
    animation: none;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__blow {
    width: 450px;
    height: 350px;
    position: absolute;
    top: -195px;
    right: -260px;
  }
}

@media screen and (min-width: 700px) {
  // .firework {
  //   height: 10rem;
  // }
}

@keyframes animationContainer {
  0% {
    width: calc(95% - 6rem);
    @media screen and (min-width: 700px) {
      width: calc(90% - 10rem);
    }
  }
  25% {
    width: calc(95% - 5rem);
    @media screen and (min-width: 700px) {
      width: calc(95% - 8rem);
    }
  }
  75% {
    width: calc(95% - 7rem);
    @media screen and (min-width: 700px) {
      width: calc(95% - 12rem);
    }
  }
  100% {
    width: calc(95% - 6rem);
    @media screen and (min-width: 700px) {
      width: calc(95% - 10rem);
    }
  }
}


@keyframes hideAndVisible {
  0% {
    transform: rotate(0) scale(0.5);
  }
  25% {
    transform: rotate(0) scale(1.15);
  }
  50% {
    transform: rotate(0) scale(0.9);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}

@keyframes animationContainerStart {
  0% {
    width: 3rem;
    height: 3rem;
  }
  100% {
    width: calc(95% - 6rem);
    height: calc(95% - 8rem);
    @media screen and (min-width: 700px) {
      width: calc(95% - 10rem);
      height: calc(95% - 9rem);
    }
  }
}

@keyframes animationContainerHorizonStart {
  0% {
    width: 3rem;
    height: 1rem;
  }
  100% {
    width: calc(95% - 9rem);
    height: 1rem;
    @media screen and (min-width: 700px) {
      width: calc(95% - 10rem);
      height: 1rem;
    }
  }
}

@keyframes animationContainerHorizon {
  0% {
    width: calc(95% - 9rem);
    @media screen and (min-width: 700px) {
      width: calc(95% - 10rem);
    }
  }
  25% {
    width: calc(95% - 8rem);
    @media screen and (min-width: 700px) {
      width: calc(90% - 8rem);
    }
  }
  75% {
    width: calc(95% - 10rem);
    @media screen and (min-width: 700px) {
      width: calc(90% - 12rem);
    }
  }
  100% {
    width: calc(95% - 9rem);
    @media screen and (min-width: 700px) {
      width: calc(95% - 10rem);
    }
  }
}
