.jackpot-window {
  width: 100vw;
  height: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(17, 19, 24, 0.9);
  z-index: 1000000;

  &__container {
    display: flex;
    flex-direction: column;
    background: #1f212e;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    width: 35%;
    height: 20%;
    border-radius: 10px;
    margin-top: 13rem;
    margin-bottom: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2d313f;
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 3.9rem;
    padding: 15px;
    box-sizing: border-box;
  }

  &__header-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
  }

  &__header-text {
    text-transform: uppercase;
    font-size: large;
    font-weight: 600;
  }

  &__header-icon {
    width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__svg {
    height: 21px;
    width: 16.1px;
    color: rgb(141, 151, 161);

    &:hover {
      color: rgb(255, 204, 0);
    }
  }

  &__cell-container {
    display: flex;
    align-items: center;
    height: 4rem;
    font-size: large;
    :nth-child(1n) {
      margin-left: 5px;
    }

  }

  &__cell-curency {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
  }

  &__currency {
    font-weight: 700;
    font-size: large;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    background: rgb(141, 151, 161);
    font-size: large;
    font-weight: 400;
    padding: 10px;
    width: 65%;
  }

  &__bets-container {
    height: 100%;
    min-height: 230px;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    box-sizing: border-box;
  }


  &__content {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    box-sizing: border-box;
  }
}