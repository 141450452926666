.coef {
  font-weight: 900;
  font-size: 110px;
  color: #FFFFFF;
  padding: 0.3rem;
  position: absolute;
  white-space: nowrap;
  top: initial;
  right: initial;
  bottom: calc(50% - 1rem);
  border-radius: 7px;
  z-index: 2;
  @media only screen and (max-width: 422px) {
    font-size: 50px;
  }
}

@media screen and (min-width: 700px) {
  .coef {
    top: initial;
  }
}