.game-window {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 65%;
  border-radius: 0 10px 0 0;
  background-position-x: 0;
  background-position-y: 100%;
  animation: backgroundAnimation 45s linear infinite, backgroundVerticalAnimation 45s linear forwards;

  &_styleCode {
    &_default {
      &.game-window_isAnimation {
        animation: backgroundAnimation 45s linear infinite, backgroundVerticalAnimation 45s linear forwards;
      }
    }
    }

  &_isPauseAnimation {
    animation-play-state: paused !important;
  }
  &__end-image {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    height: 10rem;
  }
}

@media screen and (min-width: 700px) {
  .game-window {
    padding-top: 0;
  }
}

@keyframes backgroundAnimation {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 200%;
  }
}

@keyframes backgroundVerticalAnimation {
  0% {
    background-position-y: 100%;
  }
  100% {
    background-position-y: 0%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}