.switch-box {
  width: 3.7rem;
  min-width: 3.7rem;
  height: 2rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  display: flex;
  border-radius: 6px;
  cursor: pointer;
  &__indicator {
    height: 2rem;
    width: 2rem;
    border-radius: 6px;
    background: #c0cbda;
    margin-left: 0;
    transition: all .3s ease-out;
    &_active {
      margin-left: 1.7rem;
      background: #2ecd45;
    }
  }
}